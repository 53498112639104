"use client";

import { NextIntlClientProvider, AbstractIntlMessages } from "next-intl";
import { onError, getMessageFallback } from "@/lib/i18n";
export function TranslationsProvider({
  locale,
  messages,
  timeZone,
  children
}: Readonly<{
  locale: string;
  messages: AbstractIntlMessages;
  timeZone: string;
  children: React.ReactNode;
}>) {
  return <NextIntlClientProvider locale={locale} messages={messages} timeZone={timeZone} onError={onError} getMessageFallback={getMessageFallback} data-sentry-element="NextIntlClientProvider" data-sentry-component="TranslationsProvider" data-sentry-source-file="TranslationsProvider.tsx">
      {children}
    </NextIntlClientProvider>;
}