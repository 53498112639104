"use client";

import { Loader } from "@/components/edges/ui/Loader";
import { twMerge } from "tailwind-merge";
interface GlobalLoadingProps {
  isLoading: boolean;
  loadingText: string | null;
}
export function GlobalLoading({
  isLoading,
  loadingText
}: GlobalLoadingProps) {
  if (!isLoading) return null;
  return <div className={twMerge("fixed inset-0 z-50 flex items-center justify-center bg-background-surface", "transition-opacity duration-300", isLoading ? "opacity-100" : "pointer-events-none opacity-0")} data-sentry-component="GlobalLoading" data-sentry-source-file="GlobalLoading.tsx">
      <div className="flex flex-col items-center gap-4">
        <Loader className="h-8 w-8" data-sentry-element="Loader" data-sentry-source-file="GlobalLoading.tsx" />
        {loadingText && <p className="text-sm text-text-muted">{loadingText}</p>}
      </div>
    </div>;
}