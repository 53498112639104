"use client";

import { datadogRum } from "@datadog/browser-rum";
import { User } from "@datadog/browser-core";
import { config } from "@/common/config";
import packageJson from "../../../package.json";
const TRACING_URLS = [config.identityDomain, config.supergraph.url];
export function initDatadogRum() {
  datadogRum.init({
    applicationId: config.datadog.applicationId!,
    clientToken: config.datadog.clientToken!,
    // Currently this site is used for US. When we would have europe customers we can use datadoghq.eu
    site: "datadoghq.com",
    service: "dashboard",
    env: config.environment,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [(url: string) => {
      return TRACING_URLS.some(tracingUrl => url.startsWith(tracingUrl ?? ""));
    }]
  });
}
export function setDatadogRumUser(user: User) {
  datadogRum.setUser(user);
}