"use client";

import { useRouter, usePathname } from "next/navigation";
import { useEffect, ReactNode, useRef } from "react";
import { useEventEmitter } from "../event-bus";
import type { Events } from "../event-bus";
type NavigateParams = Events["navigation:navigate"];
export const NavigationProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const emitter = useEventEmitter();
  const pathname = usePathname();
  const pendingNavigationRef = useRef<{
    flowId?: string;
    path?: string;
  } | null>(null);
  const initialPathRef = useRef(pathname);

  // When pathname changes, the navigation has completed
  useEffect(() => {
    // Skip if this is the initial render and we haven't navigated yet
    if (pathname === initialPathRef.current && !pendingNavigationRef.current) {
      return;
    }
    if (pendingNavigationRef.current?.flowId) {
      // Only end loading if we've actually navigated to the intended path
      // or if we're on a different path (user might have navigated elsewhere)
      if (!pendingNavigationRef.current.path || pathname.includes(pendingNavigationRef.current.path) || pathname !== initialPathRef.current) {
        emitter.emit("loading:end", {
          flowId: pendingNavigationRef.current.flowId
        });
        pendingNavigationRef.current = null;
      }
    }
  }, [pathname, emitter]);
  useEffect(() => {
    const handleNavigate = (params: NavigateParams) => {
      try {
        // If we already have a pending navigation with a flowId, and the new navigation
        // is to the same path but without a flowId, preserve the existing flowId
        if (pendingNavigationRef.current?.flowId && !params.flowId && pendingNavigationRef.current.path === params.path) {
          params.flowId = pendingNavigationRef.current.flowId;
        }

        // Store both flowId and target path for verification
        pendingNavigationRef.current = {
          flowId: params.flowId,
          path: params.path
        };

        // Start the navigation
        if (params.options?.replace) {
          router.replace(params.path);
        } else {
          router.push(params.path);
        }
      } catch (error) {
        console.error("Navigation failed:", error);
        if (params.flowId) {
          emitter.emit("loading:end", {
            flowId: params.flowId
          });
        }
        pendingNavigationRef.current = null;
      }
    };
    emitter.on("navigation:navigate", handleNavigate);
    return () => {
      emitter.off("navigation:navigate", handleNavigate);
    };
  }, [router, emitter, pathname]);
  return <>{children}</>;
};