"use client";

import { useAuth } from "@/hooks/auth/useAuth";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
const EXEMPT_PATHS = ["/confirm-email", "/sign-in", "/create-account", "/verify-email"];
export function RequireVerifiedEmail({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    user
  } = useAuth();
  const router = useRouter();
  const pathname = usePathname();
  useEffect(() => {
    // Don't redirect if we're already on an exempt path
    if (EXEMPT_PATHS.includes(pathname)) {
      return;
    }

    // Only redirect if we have a user and their email isn't verified
    if (user && !user.isEmailVerified) {
      router.push("/confirm-email");
    }
  }, [user, pathname, router]);

  // If we're on a protected path and the email isn't verified, don't render children
  if (!EXEMPT_PATHS.includes(pathname) && user && !user.isEmailVerified) {
    return null;
  }
  return <>{children}</>;
}