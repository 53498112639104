"use client";

import { ApolloLink, HttpLink, split, Operation } from "@apollo/client";
import { ApolloNextAppProvider, ApolloClient, InMemoryCache } from "@apollo/experimental-nextjs-app-support";
import { authLink } from "@/graphql/links/authLink";
import ApolloLinkLogger from "apollo-link-logger";
import { customHeadersLink } from "@/graphql/links/customHeadersLink";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { setVerbosity } from "ts-invariant";
import { config } from "@/common/config";
import { getMainDefinition } from "@apollo/client/utilities";

// Development configuration for debugging
if (config.environment === "development") {
  setVerbosity("debug");
  loadDevMessages();
  loadErrorMessages();
}
function makeClient() {
  // Primary (existing) API link
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_SUPERGRAPH_URL,
    fetchOptions: {
      cache: "force-cache"
    }
  });

  // Secondary (Payload CMS) API link
  const payloadLink = new HttpLink({
    uri: "https://device.cms.texture.energy/api/graphql",
    // Set this to your Payload CMS URL
    fetchOptions: {
      cache: "force-cache"
    }
  });

  // Use `split` to decide which link to use based on operation name or other logic
  const link = split(({
    query
  }: Operation) => {
    const definition = getMainDefinition(query);
    // Custom logic: route to Payload CMS if the operation name starts with "Payload"
    return Boolean(definition.kind === "OperationDefinition" && definition.operation === "query" && definition.name?.value.startsWith("Payload") // Example logic for Payload CMS queries
    );
  }, payloadLink,
  // Use Payload CMS for matching operations
  httpLink // Default to the existing API
  );

  // Combine all links including custom links
  const apolloLinks = [customHeadersLink, authLink, link];
  if (config.environment === "development") {
    apolloLinks.unshift(ApolloLinkLogger);
  }

  // Create and return Apollo Client
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from(apolloLinks)
  });
}
export default function ApolloClientProvider({
  children
}: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient} data-sentry-element="ApolloNextAppProvider" data-sentry-component="ApolloClientProvider" data-sentry-source-file="ApolloClientProvider.tsx">
      {children}
    </ApolloNextAppProvider>;
}