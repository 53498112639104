"use client";

import React, { useEffect } from "react";
import posthog from "posthog-js";
import { PostHogApi } from "./auth/types";
export default function PostHogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    // Only initialize if the key and host are available
    const apiKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
    const apiHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;
    if (!apiKey || !apiHost) {
      // If PostHog is not configured, skip initialization but allow the app to continue
      console.warn("PostHog analytics is not configured. Skipping initialization.");
      return;
    }

    // Initialize PostHog
    try {
      posthog.init(apiKey, {
        api_host: apiHost,
        capture_pageview: true,
        // Automatically capture pageviews
        capture_pageleave: true,
        // Track when users leave the page
        autocapture: true,
        // Automatically track clicks, form submissions etc.
        disable_session_recording: false,
        // Enable session recording
        persistence: "localStorage"
      });

      // Make PostHog globally available via window.posthog
      // This allows other parts of the application to access PostHog without importing it directly
      // Particularly useful for the AuthProvider which needs to identify users but exists in a different part of the component tree
      window.posthog = posthog as PostHogApi;
      console.log("PostHog initialized successfully");
    } catch (error) {
      // If PostHog fails to initialize, log the error but don't crash the app
      console.error("Failed to initialize PostHog:", error);
    }

    // Clean up on unmount
    return () => {
      try {
        // Use type assertion to access the shutdown method
        const posthogInstance = posthog as unknown as {
          shutdown?: () => void;
        };
        if (posthogInstance && typeof posthogInstance.shutdown === "function") {
          posthogInstance.shutdown();
        }
      } catch (error) {
        console.error("Failed to shut down PostHog:", error);
      }
    };
  }, []);
  return <>{children}</>;
}