"use client";

import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
export function setSentryUser(user: Sentry.User) {
  Sentry.setUser(user);

  // Add LogRocket session URL to Sentry scope
  try {
    LogRocket.getSessionURL(sessionURL => {
      try {
        const scope = Sentry.getCurrentScope();
        if (scope && sessionURL) {
          scope.setExtra("logrocketSessionURL", sessionURL);
        }
      } catch (error) {
        console.error("Failed to set LogRocket session URL in Sentry:", error);
      }
    });
  } catch (error) {
    console.error("Failed to get LogRocket session URL:", error);
  }
}