"use client";

// LoadingProvider.jsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { GlobalLoading } from "./GlobalLoading";
import { useEventEmitter } from "../event-bus";
interface LoadingOperation {
  active: boolean;
  message: string;
  timestamp: number;
}
interface LoadingContextType {
  isLoading: boolean;
  loadingMessage: string;
  startLoading: (id: string, message?: string) => void;
  updateLoadingMessage: (id: string, message: string) => void;
  finishLoading: (id: string) => void;
  getActiveOperations: () => string[];
  getOperationCount: () => number;
}

// Create the context
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);
interface LoadingProviderProps {
  children: ReactNode;
}
export const LoadingProvider = ({
  children
}: LoadingProviderProps) => {
  const emitter = useEventEmitter();
  // Track all active loading operations with their messages
  const [loadingOperations, setLoadingOperations] = useState<Record<string, LoadingOperation>>({});

  // Derived states
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  // Expose methods for direct use within components
  const startLoading = (id: string, message = "") => {
    setLoadingOperations(prev => ({
      ...prev,
      [id]: {
        active: true,
        message,
        timestamp: Date.now()
      }
    }));
  };
  const updateLoadingMessage = (id: string, message: string) => {
    setLoadingOperations(prev => {
      if (!prev[id]) return prev;
      return {
        ...prev,
        [id]: {
          ...prev[id],
          message,
          timestamp: Date.now()
        }
      };
    });
  };
  const finishLoading = (id: string) => {
    setLoadingOperations(prev => {
      const updated = {
        ...prev
      };
      delete updated[id];
      return updated;
    });
  };

  // Listen for loading events from other providers
  useEffect(() => {
    const handleLoadingStart = ({
      text,
      flowId
    }: {
      text: string;
      flowId?: string;
    }) => {
      const id = flowId || `loading-${Date.now()}`;
      startLoading(id, text);
    };
    const handleLoadingUpdate = ({
      text,
      flowId
    }: {
      text: string;
      flowId?: string;
    }) => {
      if (flowId) {
        updateLoadingMessage(flowId, text);
      }
    };
    const handleLoadingEnd = ({
      flowId
    }: {
      flowId?: string;
    }) => {
      if (flowId) {
        finishLoading(flowId);
      } else {
        // Clear all loading operations when no flowId is provided
        setLoadingOperations({});
      }
    };

    // Register event listeners
    emitter.on("loading:start", handleLoadingStart);
    emitter.on("loading:update", handleLoadingUpdate);
    emitter.on("loading:end", handleLoadingEnd);

    // Clean up listeners
    return () => {
      emitter.off("loading:start", handleLoadingStart);
      emitter.off("loading:update", handleLoadingUpdate);
      emitter.off("loading:end", handleLoadingEnd);
    };
  }, [emitter]);

  // Update derived states when operations change
  useEffect(() => {
    const operationIds = Object.keys(loadingOperations);
    const isCurrentlyLoading = operationIds.length > 0;

    // Update loading state
    setIsLoading(isCurrentlyLoading);

    // Update loading message - use the message from the most recent operation
    if (isCurrentlyLoading) {
      // Find operation with the most recent timestamp
      let mostRecentOp: LoadingOperation = {
        active: false,
        message: "",
        timestamp: 0
      };
      for (const [, op] of Object.entries(loadingOperations)) {
        if (op.timestamp > mostRecentOp.timestamp) {
          mostRecentOp = op;
        }
      }
      setLoadingMessage(mostRecentOp.message || "");
    } else {
      setLoadingMessage("");
    }
  }, [loadingOperations]);

  // Optional: Clear hanging loading operations after timeout
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      setLoadingOperations(prev => {
        const updated = {
          ...prev
        };
        let changed = false;

        // Check for operations that have been active for > 30 seconds
        Object.entries(updated).forEach(([id, op]) => {
          if (now - op.timestamp > 30000) {
            // 30 seconds timeout
            delete updated[id];
            changed = true;
            console.warn(`Loading operation ${id} timed out and was cleared automatically`);
          }
        });
        return changed ? updated : prev;
      });
    }, 10000); // Check every 10 seconds

    return () => clearInterval(interval);
  }, []);

  // Context value
  const value: LoadingContextType = {
    isLoading,
    loadingMessage,
    startLoading,
    updateLoadingMessage,
    finishLoading,
    // Helpful additional methods
    getActiveOperations: () => Object.keys(loadingOperations),
    getOperationCount: () => Object.keys(loadingOperations).length
  };
  return <LoadingContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="LoadingProvider" data-sentry-source-file="LoadingProvider.tsx">
      {children}
      <GlobalLoading isLoading={isLoading} loadingText={loadingMessage || null} data-sentry-element="GlobalLoading" data-sentry-source-file="LoadingProvider.tsx" />
    </LoadingContext.Provider>;
};

// Custom hook for components to use the loading context
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
interface LoadingOverlayProps {
  customStyles?: React.CSSProperties;
  spinnerComponent?: React.ReactNode;
}

// LoadingOverlay component for easy implementation
export const LoadingOverlay = ({
  customStyles,
  spinnerComponent
}: LoadingOverlayProps) => {
  const {
    isLoading,
    loadingMessage
  } = useLoading();
  if (!isLoading) return null;
  return <div className="loading-overlay" style={{
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
    ...customStyles
  }} data-sentry-component="LoadingOverlay" data-sentry-source-file="LoadingProvider.tsx">
      {spinnerComponent || <div className="spinner" style={{
      width: "50px",
      height: "50px",
      border: "5px solid #f3f3f3",
      borderTop: "5px solid #3498db",
      borderRadius: "50%",
      animation: "spin 1s linear infinite"
    }}></div>}

      {loadingMessage && <p className="loading-message" style={{
      color: "white",
      marginTop: "20px",
      fontSize: "16px"
    }}>
          {loadingMessage}
        </p>}
    </div>;
};

// Add a keyframe animation for the spinner (in your CSS file)
// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }