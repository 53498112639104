// @ts-nocheck

import { setContext } from "@apollo/client/link/context";

const LEGACY_TOKEN_KEY = "dashboard-token";

export const authLink = setContext(async (_, { headers = {}, ...rest }) => {
  // Check if we have a new auth token first, then fall back to legacy
  let authToken = null;

  if (typeof window !== "undefined") {
    // First try to use the new auth token
    authToken = localStorage.getItem("accessToken");

    // If not found, fall back to legacy token
    if (!authToken) {
      authToken = localStorage.getItem(LEGACY_TOKEN_KEY);
    }
  }

  const authHeaders = authToken
    ? {
        Authorization: `Bearer ${authToken}`,
      }
    : {};

  if (process.env.ENVIRONMENT === "development") {
    authHeaders["X-Texture-Supergraph-Secret"] =
      process.env.SUPERGRAPH_CLIENT_SECRET;
  }

  return {
    ...rest,
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});
