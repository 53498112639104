"use client";

import { useEffect } from "react";
import { refreshTokenAPI } from "@/api/auth";
import { useApolloClient } from "@apollo/client";
import { useAuth } from "@/hooks/auth";
import { useNotice } from "@/components/edges/ui/Notice/NoticeProvider";
export const SessionManager = () => {
  const client = useApolloClient();
  const {
    refreshToken,
    signOut
  } = useAuth();
  const {
    addNotice
  } = useNotice();
  useEffect(() => {
    const storedRefreshToken = localStorage.getItem("refreshToken");

    // Early return if no tokens
    if (!refreshToken || !storedRefreshToken) {
      console.warn("[SessionManager] No refresh tokens available");
      return;
    }

    // Early return if tokens don't match
    if (refreshToken !== storedRefreshToken) {
      console.warn("[SessionManager] Token mismatch - signing out");
      signOut(true);
      return;
    }

    // Function to refresh the token
    const refreshTokens = async () => {
      const currentStoredToken = localStorage.getItem("refreshToken");
      if (!currentStoredToken) {
        console.warn("[SessionManager] No refresh token in storage");
        signOut(true);
        return;
      }
      try {
        const {
          bearerToken,
          refreshToken: newRefreshToken
        } = await refreshTokenAPI(client, currentStoredToken);
        localStorage.setItem("accessToken", bearerToken);
        localStorage.setItem("refreshToken", newRefreshToken);
        addNotice({
          title: "Session Refreshed",
          description: "Your session has been automatically renewed.",
          variant: "info",
          duration: 3000,
          position: "bottom-right"
        });
      } catch (error) {
        console.error("[SessionManager] Token refresh failed:", error);
        signOut(true);
      }
    };

    // Initial token refresh
    refreshTokens();

    // Set up interval for subsequent refreshes
    const refreshInterval = setInterval(refreshTokens, 14 * 60 * 1000); // 14 minutes

    return () => clearInterval(refreshInterval);
  }, [refreshToken, signOut, client, addNotice]);
  return null;
};