"use client";

import React, { createContext, useContext, useState } from "react";
import { setLoggedUser as setLegacyUser } from "@/state/user/useLoggedUser";
import { LoggedUser, PreferredTemperatureUnit } from "@/@codegen/supergraph";
import posthog, { PostHog } from "posthog-js";
import { PostHogApi, Membership as AuthMembership } from "./auth/types";

// Define the User interface for the new auth system
export interface User {
  id: string;
  name?: string;
  email?: string;
  preferredTemperatureUnit?: string;
  preferredTimeZone?: string;
  profilePhoto?: string;
  isEmailVerified?: boolean;
  memberships?: AuthMembership[];
  // Add other user properties as needed
}

// Define the context value interface
interface UserContextValue {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  updateUserPreferences: (preferences: Partial<User>) => void;
  identifyInPostHog: (user: User) => void;
  resetPostHogIdentity: () => void;
}

// Create context with default values
const UserContext = createContext<UserContextValue | null>(null);

// Custom hook to use the user context
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

// Helper function to check for valid window.posthog
const isPostHogAvailable = () => {
  return typeof window !== "undefined" && window.posthog && typeof window.posthog.identify === "function";
};

// NOTE: The global interface is already defined in auth/types.ts
// We don't need to redefine it here

export const UserProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [user, setUserState] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  // Function to identify user in PostHog
  const identifyInPostHog = (user: User) => {
    if (!user || !isPostHogAvailable()) return;
    try {
      posthog.identify(user.id, {
        name: user.name || "",
        email: user.email || "",
        preferredTemperatureUnit: user.preferredTemperatureUnit,
        preferredTimeZone: user.preferredTimeZone,
        isEmailVerified: user.isEmailVerified,
        numberOfMemberships: user.memberships?.length || 0
      });
      console.log("User identified in PostHog:", user.id);
    } catch (error) {
      console.error("Failed to identify user in PostHog:", error);
    }
  };

  // Function to reset PostHog identity
  const resetPostHogIdentity = () => {
    if (!isPostHogAvailable()) return;
    try {
      posthog.reset();
      console.log("PostHog identity reset");
    } catch (error) {
      console.error("Failed to reset PostHog identity:", error);
    }
  };

  // Function to update the user state and identify with PostHog
  const setUser = (newUser: User | null) => {
    setUserState(newUser);
    if (newUser) {
      identifyInPostHog(newUser);

      // Create a firstName and lastName from the name
      const firstName = newUser.name?.split(" ")[0] || "";
      const lastName = newUser.name?.split(" ").slice(1).join(" ") || "";

      // Sync with legacy user store
      const now = new Date().toISOString();

      // Convert auth memberships to supergraph memberships
      const convertedMemberships = (newUser.memberships || []).map(authMembership => {
        return {
          __typename: "Membership",
          id: authMembership.id,
          role: authMembership.role,
          isAdmin: authMembership.isAdmin,
          organizationId: authMembership.organization.id,
          owner: false,
          // Default value
          createdAt: now,
          updatedAt: now,
          userId: newUser.id,
          organization: {
            __typename: "Organization",
            id: authMembership.organization.id,
            name: authMembership.organization.name
          },
          userProfile: {
            __typename: "UserProfile",
            id: `profile-${newUser.id}`,
            firstName,
            lastName,
            fullName: newUser.name || ""
          }
        };
      });

      // Create a partial user object with the required fields
      // We first create the base object, cast to 'unknown', then cast to LoggedUser
      // This is to handle the type conflicts between the complex structures
      const legacyUser = {
        __typename: "LoggedUser",
        id: newUser.id,
        createdAt: now,
        updatedAt: now,
        externalUserId: newUser.id,
        supertokensId: newUser.id,
        // Deprecated but required
        firstName,
        lastName,
        fullName: newUser.name || "",
        profilePhoto: newUser.profilePhoto || "",
        isTextureEmployee: false,
        areEmailsVerified: newUser.isEmailVerified || false,
        lastAuthenticatedOrganizationId: null,
        invitations: [],
        emails: [],
        authenticatedOrganization: null,
        email: {
          __typename: "Email",
          id: `email-${newUser.id}`,
          email: newUser.email || "",
          verified: newUser.isEmailVerified || false,
          domain: newUser.email?.split("@")[1] || "",
          isPersonalEmailDomain: true,
          createdAt: now,
          updatedAt: now,
          token: "",
          userId: newUser.id,
          user: {} as any
        },
        profile: {
          __typename: "UserProfile",
          id: `profile-${newUser.id}`,
          firstName,
          lastName,
          fullName: newUser.name || "",
          profilePhoto: newUser.profilePhoto || "",
          preferredTemperatureUnit: newUser.preferredTemperatureUnit as PreferredTemperatureUnit || PreferredTemperatureUnit.Celsius,
          preferredTimeZone: newUser.preferredTimeZone || "",
          legacyProfile: false,
          createdAt: now,
          updatedAt: now,
          externalUserId: newUser.id
        },
        // These memberships are already correctly typed, but TypeScript doesn't recognize this
        // Using a type assertion (as unknown, then as LoggedUser) to resolve the type error
        memberships: convertedMemberships
      } as unknown as LoggedUser;
      setLegacyUser(legacyUser);
    } else {
      resetPostHogIdentity();
      // Clear legacy user store
      setLegacyUser(null);
    }
  };

  // Function to update user preferences
  const updateUserPreferences = (preferences: Partial<User>) => {
    if (!user) return;
    const updatedUser = {
      ...user,
      ...preferences
    };
    setUser(updatedUser);

    // In a real application, you would persist these preferences to your backend
  };
  const value = {
    user,
    setUser,
    loading,
    updateUserPreferences,
    identifyInPostHog,
    resetPostHogIdentity
  };
  return <UserContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="UserProvider.tsx">{children}</UserContext.Provider>;
};
export default UserProvider;