"use client";

import { ReactNode, useEffect, useState } from "react";
import { GrowthBook } from "@growthbook/growthbook";
import { GrowthBookProvider as GBProvider } from "@growthbook/growthbook-react";
import { useAuth, useOrganization } from "@/hooks";
interface GrowthBookProviderProps {
  children: ReactNode;
  initialFeatures: Record<string, any>;
}
const GrowthBookProvider = ({
  children,
  initialFeatures
}: GrowthBookProviderProps) => {
  const [growthbook, setGrowthbook] = useState<GrowthBook | null>(null);
  const {
    user
  } = useAuth();
  const {
    currentOrganization
  } = useOrganization();
  useEffect(() => {
    const gb = new GrowthBook({
      apiHost: "https://cdn.growthbook.io",
      clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: process.env.NODE_ENV !== "production"
    });
    console.log(process.env.NODE_ENV);
    gb.setFeatures(initialFeatures);
    gb.loadFeatures({
      autoRefresh: true
    }).then(() => {
      setGrowthbook(gb);
    });
    return () => {
      gb.destroy();
    };
  }, [initialFeatures]);
  useEffect(() => {
    if (user && growthbook) {
      // Create tracking data from user context
      const trackingUserData = {
        id: user.id,
        userId: user.id,
        email: user.email,
        name: user.name,
        isTextureEmployee: user.isTextureEmployee,
        membershipRole: user.memberships?.[0]?.role,
        organizationId: currentOrganization?.id
      };

      // Ensure Texture employees are properly identified by email domain
      const isTextureDomain = user.email?.endsWith("@texturehq.com");
      if (isTextureDomain && !trackingUserData.isTextureEmployee) {
        trackingUserData.isTextureEmployee = true;
      }
      growthbook.setAttributes(trackingUserData);
    }
  }, [user, growthbook, currentOrganization]);
  if (!growthbook) {
    return null; // or a loading indicator
  }
  return <GBProvider growthbook={growthbook} data-sentry-element="GBProvider" data-sentry-component="GrowthBookProvider" data-sentry-source-file="GrowthBookProvider.tsx">{children}</GBProvider>;
};
export default GrowthBookProvider;