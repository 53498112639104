export const ROUTES = {
  public: {
    signUp: "/sign-up",
    signIn: "/sign-in",
    resetPassword: "/reset-password",
    notFound: "/not-found",
  },
  private: {
    admin: "/admin",
    adminOrgs: "/admin/orgs",
    adminUsers: "/admin/users",
    apiKeys: "/developer/api-keys",
    apps: "/apps",
    base: "/",
    billing: "/settings/billing",
    connect: "/developer/connect",
    customer: "/customers/",
    customers: "/customers",
    destinations: "/developer/destinations",
    developer: "/developer",
    device: "/devices/",
    deviceOld: "/network/devices/[id]", // used solely for redirecting old routes
    devices: "/devices",
    devicesOld: "/network", // used solely for redirecting old routes
    emailVerified: "/email-verification",
    collections: "/collections",
    generation: "/generation",
    programs: "/programs",
    reports: "/reports",
    home: "/workspaces",
    leads: "/leads",
    onboardingOrganization: "/onboarding/organization",
    organizationWorkspacesList: "/settings/workspaces",
    organization: "/settings/organization",
    pendingInvitations: "/onboarding/pending-invitations",
    profile: "/settings/user-profile",
    settings: "/settings",
    site: "/sites/",
    sites: "/sites",
    sources: "/sources",
    usage: "/settings/usage",
    utilityData: "/sources/utility-data",
    verifyEmail: "/verify-email",
  },
} as const;
