"use client";

import { AriaRouterProvider, AuthProvider, ColorModeProvider, LoadingProvider, NavigationProvider, OrganizationProvider, InvitationsProvider, WorkspaceProvider, TrackingProvider, GrowthBookProvider } from "@/providers";
import { NoticeProvider } from "@/components/edges/ui/Notice/NoticeProvider";
import { EventEmitterProvider } from "./event-bus";
import { RequireVerifiedEmail } from "@/components/auth/RequireVerifiedEmail";
interface ClientProvidersProps {
  children: React.ReactNode;
  isBonfireRoute?: boolean;
  initialFeatures: Record<string, any>;
}
export function ClientProviders({
  children,
  initialFeatures
}: ClientProvidersProps) {
  return <EventEmitterProvider data-sentry-element="EventEmitterProvider" data-sentry-component="ClientProviders" data-sentry-source-file="ClientProviders.tsx">
      <ColorModeProvider data-sentry-element="ColorModeProvider" data-sentry-source-file="ClientProviders.tsx">
        <NoticeProvider data-sentry-element="NoticeProvider" data-sentry-source-file="ClientProviders.tsx">
          <LoadingProvider data-sentry-element="LoadingProvider" data-sentry-source-file="ClientProviders.tsx">
            <NavigationProvider data-sentry-element="NavigationProvider" data-sentry-source-file="ClientProviders.tsx">
              <AriaRouterProvider data-sentry-element="AriaRouterProvider" data-sentry-source-file="ClientProviders.tsx">
                <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="ClientProviders.tsx">
                  <OrganizationProvider data-sentry-element="OrganizationProvider" data-sentry-source-file="ClientProviders.tsx">
                    {/* TrackingProvider needs access to current user and organization, so must at least be below AuthProvider and OrganizationProvider */}
                    <TrackingProvider data-sentry-element="TrackingProvider" data-sentry-source-file="ClientProviders.tsx">
                      {/* Place GrowthBookProvider after AuthProvider so it has access to the authenticated user */}
                      <GrowthBookProvider initialFeatures={initialFeatures} data-sentry-element="GrowthBookProvider" data-sentry-source-file="ClientProviders.tsx">
                        <RequireVerifiedEmail data-sentry-element="RequireVerifiedEmail" data-sentry-source-file="ClientProviders.tsx">
                          <InvitationsProvider data-sentry-element="InvitationsProvider" data-sentry-source-file="ClientProviders.tsx">
                            <WorkspaceProvider data-sentry-element="WorkspaceProvider" data-sentry-source-file="ClientProviders.tsx">{children}</WorkspaceProvider>
                          </InvitationsProvider>
                        </RequireVerifiedEmail>
                      </GrowthBookProvider>
                    </TrackingProvider>
                  </OrganizationProvider>
                </AuthProvider>
              </AriaRouterProvider>
            </NavigationProvider>
          </LoadingProvider>
        </NoticeProvider>
      </ColorModeProvider>
    </EventEmitterProvider>;
}