"use client";

import React from "react";
import { config } from "@/common/config";
import { usePathname, useSearchParams } from "next/navigation";
import useAnalytics from "@/hooks/useAnalytics";
import { findClosestRoute } from "@/utils/routes";
import { initDatadogRum, setDatadogRumUser } from "./datadog";
import { setSentryUser } from "./sentry";
import { identifyLogRocketUser, initLogRocket } from "./logrocket";
import { useAuth, useOrganization } from "@/hooks";
export default function TrackingProvider(props: React.PropsWithChildren) {
  const {
    loadAnalytics,
    track,
    identify
  } = useAnalytics();
  const {
    user
  } = useAuth();
  const {
    currentOrganization
  } = useOrganization();
  React.useEffect(() => {
    if (config.environment === "production") {
      initLogRocket();
      initDatadogRum();
      loadAnalytics(config.rudderstack.writeKey!, config.rudderstack.dataPlaneUrl!);
    }
  }, []);
  React.useEffect(() => {
    if (!user) return;
    if (!currentOrganization) return;
    if (config.environment === "production") {
      setDatadogRumUser({
        id: user.id,
        email: user.email,
        name: user.name,
        organizationName: currentOrganization.name,
        organizationId: currentOrganization.id
      });
      setSentryUser({
        id: user.id,
        email: user.email,
        name: user.name,
        organizationName: currentOrganization.name,
        organizationId: currentOrganization.id
      });
      identifyLogRocketUser({
        userId: user.id,
        email: user.email,
        name: user.name,
        organizationName: currentOrganization.name,
        organizationId: currentOrganization.id
      });
      identify({
        userId: user.id,
        email: user.email,
        firstName: user.name,
        lastName: user.name,
        organizationName: currentOrganization.name,
        organizationId: currentOrganization.id
      });
    }
  }, [user, currentOrganization]);
  const pathname = usePathname();
  const search = useSearchParams();
  React.useEffect(() => {
    const folder = findClosestRoute(pathname);
    track("page-view", {
      pathname,
      folder,
      search: search.toString(),
      success: true
    });
  }, [pathname, search]);
  return <>{props.children}</>;
}